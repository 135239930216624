import React from 'react';
import { Heading4 } from '@latitude/heading';
import { Box } from '@/components/Box/Box';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import PageData from '@/data/pages/sales-tools.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator/PersonalLoanCalculator';
import PLDisclaimerContent from '@/components/PersonalLoanCalculator/PersonalLoanDisclaimerContent';
import Lframe from '@/components/Lframe/Lframe';
import { ALIGN, COLOR, MARGIN, PADDING, POSITION } from '../utils/constants';

/**
 * This page is created for LFS internal use only to support sales
 *
 * - Page is NOT indexed by search engine
 */

const SalesToolsPage = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer">
      <Box backgroundColor={COLOR.GREY6}>
        {/* This page is for internal use only, should NOT influence SEO by any means */}
        <Metadata noIndex noFollow title={PageData.title} />
        <Lframe
          infinity
          style={{
            zIndex: 'auto'
          }}
        />
        <HeroBranded
          title={PageData.content.hero.title}
          text={<>{PageData.content.hero.intro}</>}
        />
        <div className="d-none d-lg-block">
          <StickyNavigationBranded
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
          />
        </div>

        {/**
         * Sales PL repayment calculator:
         * - has all analytics disabled
         * - has apply CTA disabled
         */}
        <Box
          id={PageData.nav[0].anchor}
          backgroundColor={COLOR.BLUE_LIGHT_BRIGHT}
          position={POSITION.RELATIVE}
        >
          <Box padding={`${PADDING.P48} 15px 0 15px`}>
            <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER}>
              Sales PL Repayments Calculator
            </Heading4>
          </Box>
          <Box isResponsive margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}>
            <PersonalLoanCalculator
              loans1
              salesMode
              disclaimerContent={<PLDisclaimerContent />}
            />
          </Box>
        </Box>
      </Box>
    </main>
  </Layout>
);

export default SalesToolsPage;
